import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import * as config from '@/utils/config'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useProductsList(dataMarked) {
  const searchQueryPublication = ref('')
  const toast = useToast()
  const showTablePublication = ref(false)
  const refPublicationListTable = ref(null)
  const tableColumnsPublication = [
    { key: 'title', sortable: false },
  ]
  const totalPublications = ref(0)
  const currentPagePublication = ref(1)
  const perPagePublication = ref(10)
  const publicationData = ref([])
  const regionId = router.currentRoute.params.id

  const fetchPublication = async regionQuery => {
    console.log("publication", regionQuery)
    if(regionQuery || regionId){
      showTablePublication.value = true
      store
        .dispatch('product/fetchProducts', {
          search: searchQueryPublication.value,
          limit: perPagePublication.value,
          page: currentPagePublication.value,
          region_ids: router.currentRoute.name
            === 'detail-spotlight'
            ? regionId : regionQuery,
          offering_type: 'publication',
          status: 'approved',
          order_by: 'created_at',
          order_type: 'desc',
        })
        .then(response => {
          publicationData.value = response.body.data.map(x => ({
            ...x,
            title: `[Publication] - ${x.title}`,
            checked: false,
          }))
          if (dataMarked) {
            // console.log(dataMarked)
            dataMarked.value.forEach(x => {
              const idx = publicationData.value.findIndex(i => i.id === x?.product?.id)
              if (idx !== -1) {
                publicationData.value[idx].checked = true
              }
            })
          }
          totalPublications.value = response.body.total
          showTablePublication.value = false
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching products list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          showTablePublication.value = false
        })
    }

  }
  const timeout = ref(null)
  if(!dataMarked){
    fetchPublication()
  }
  watch(
    [
      currentPagePublication,
      perPagePublication,
      searchQueryPublication,
    ],
    () => {
      showTablePublication.value = true
      clearTimeout(timeout.value)

      timeout.value = setTimeout(() => {
        // fetchPublication()
        showTablePublication.value = false
      }, 600)
    },
  )

  return {
    timeout,
    showTablePublication,
    fetchPublication,
    tableColumnsPublication,
    perPagePublication,
    currentPagePublication,
    totalPublications,
    searchQueryPublication,
    refPublicationListTable,
    publicationData,
  }
}
