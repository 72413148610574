<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card
      class="product-edit-wrapper"
      :title="`Region: ${
        productEdit.region_id ? productEdit.region_id.toUpperCase() : ''
      }`"
    >
      <validation-observer ref="editProductValidation">
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <!-- Title -->
            <b-col md="6">
              <b-form-group
                label="Title"
                label-for="product-edit-title"
                class="mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="product-edit-title"
                    v-model="productEdit.title"
                    @input.native="onChangeTitle"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Price -->
            <b-col md="6">
              <b-form-group
                label="Price"
                label-for="product-edit-title"
                class="mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Price"
                  rules="required"
                >
                  <b-form-input
                    id="product-edit-title"
                    v-model="productEdit.from_price"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Category -->
            <b-col md="6">
              <b-form-group
                label="Category"
                label-for="product-edit-category"
                class="mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Category"
                  rules="required"
                >
                  <v-select
                    id="product-edit-category"
                    v-model="productEdit.category_ids"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productCategory"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Slug -->
            <b-col md="6">
              <b-form-group
                label="Slug"
                label-for="product-edit-slug"
                class="mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Slug"
                  rules="required"
                >
                  <b-form-input
                    id="product-edit-slug"
                    v-model="productEdit.slug"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Tags -->
            <b-col md="6">
              <b-form-group
                v-if="productTags.length > 0"
                label="Tags"
                label-for="tags-edit-vendor"
                class="mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Tags"
                  rules="required"
                >
                  <v-select
                    id="region-edit-vendor"
                    v-model="productEdit.tag_ids"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productTags"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Product Type -->
            <b-col md="6">
              <b-form-group
                v-if="productType.length > 0"
                label="Asset type"
                label-for="product-edit-type"
                class="mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Asset type"
                  rules="required"
                >
                  <v-select
                    id="product-edit-type"
                    v-model="productEdit.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productType"
                    @input="(val) => updateProductType(val)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Roles -->
            <b-col md="6">
              <b-form-group
                label="Role"
                label-for="product-add-role"
                class="mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Role"
                  rules="required"
                >
                  <v-select
                    id="product-add-role"
                    v-model="productEdit.product_roles"
                    multiple
                    :options="productRole"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Business challenge -->
            <b-col md="6">
              <b-form-group
                label="Business Challenges"
                label-for="product-add-business"
                class="mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Business Challenges"
                  rules="required"
                >
                  <v-select
                    id="product-add-role"
                    v-model="productEdit.product_bussiness_challenges"
                    multiple
                    :options="productBussiess"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Price region -->
            <b-col md="12">
              <label for="region-price"
                >Please choose region to create asset</label
              >
              <transition-group
                id="region-price"
                name="custom-classes"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
                :duration="300"
              >
                <b-card-body v-for="index in countRegion" :key="index">
                  <!-- repeater from -->
                  <b-col cols="12">
                    <div class="border rounded p-2">
                      <div
                        v-if="arrayRegion.length > 1"
                        class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItemRegion(index - 1)"
                        />
                      </div>
                      <!-- Langugage -->
                      <b-row>
                        <b-col md="6">
                          <b-form-group
                            label="Langugage"
                            label-for="product-add-region"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Langugage"
                              rules="required"
                            >
                              <v-select
                                id="product-add-region"
                                v-model="arrayRegion[index - 1].region_id"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="regionOptions"
                                :clearable="false"
                                @input="
                                  (val) => updateItemFormRegion(index - 1, val)
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Price -->
                        <b-col md="6">
                          <b-form-group
                            label="Price"
                            label-for="product-add-price"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Price"
                              rules="required"
                            >
                              <b-input-group>
                                <b-form-input
                                  id="product-add-price"
                                  v-model="arrayRegion[index - 1].from_price"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-card-body>
              </transition-group>
            </b-col>
            <!-- <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="ml-1"
                size="sm"
                variant="primary"
                @click="addNewItemInItemFormRegion"
              >
                Add region
              </b-button>
            </b-col> -->
            <!-- description form -->
            <b-col :key="componentKey" md="12">
              <transition-group
                name="custom-classes"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
                :duration="300"
              >
                <b-card-body v-for="index in count" :key="index">
                  <!-- repeater from -->
                  <b-col cols="12">
                    <div class="border rounded p-2">
                      <div
                        v-if="arrayDescription.length > 1 && index !== 1"
                        class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index - 1)"
                        />
                      </div>
                      <b-row>
                        <!-- Language -->
                        <b-col md="6">
                          <b-form-group
                            label="Language"
                            label-for="product-edit-region"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Language"
                              rules="required"
                            >
                              <v-select
                                id="region-edit-region"
                                v-model="arrayDescription[index - 1].region_id"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="languageOptions"
                                :clearable="false"
                                :disabled="index === 1"
                                @input="(val) => updateItemForm(index - 1, val)"
                                :selectable="
                                  (option) => {
                                    return !arrayDescription.find(
                                      (x) => x.region_id.value === option.value
                                    );
                                  }
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Title -->
                        <b-col md="6">
                          <b-form-group
                            label="Title"
                            label-for="product-edit-title"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Title"
                              rules="required"
                            >
                              <b-form-input
                                id="product-edit-title"
                                v-model="arrayDescription[index - 1].title"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Summary -->
                        <b-col md="12">
                          <b-form-group
                            label="Summary"
                            label-for="product-edit-summary"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Summary"
                              rules="required"
                            >
                              <b-form-input
                                id="product-edit-summary"
                                v-model="arrayDescription[index - 1].summary"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <!-- Asset overview -->
                      <b-col cols="12">
                        <b-form-group
                          label="Asset overview"
                          label-for="product-content"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Asset overview"
                            rules="required"
                          >
                            <quill-editor
                              id="product-content"
                              v-model="arrayDescription[index - 1].description"
                              class="editor-form"
                              :options="snowOption"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Recommended for -->
                      <b-row>
                        <b-col
                          cols="11"
                          style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                          "
                        >
                          <b-form-group
                            label="Recommended for"
                            label-for="product-recommended"
                          >
                            <swiper
                              :ref="'myRecommended' + index"
                              style="
                                border: solid 1px;
                                border-color: #b8c2cc;
                                border-radius: 1rem;
                                padding: 1rem;
                              "
                              class="swiper-multiple"
                              :options="swiperOptions"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :style="
                                !arrayDescription[index - 1].recomendFors.length
                                  ? 'height: 16rem;'
                                  : ''
                              "
                            >
                              <template
                                v-if="
                                  arrayDescription[index - 1].recomendFors
                                    .length
                                "
                              >
                                <swiper-slide
                                  v-for="(
                                    data, indexRecommend
                                  ) in arrayDescription[index - 1].recomendFors"
                                  :key="indexRecommend + 'recommend' + index"
                                >
                                  <b-col>
                                    <b-row
                                      style="
                                        border: dashed 2px;
                                        border-radius: 1rem;
                                        border-color: #db536a;
                                        cursor: pointer;
                                        height: 10rem;
                                        justify-content: center;
                                      "
                                    >
                                      <b-col
                                        cols="10"
                                        @click="
                                          openSetImageModal(
                                            index - 1,
                                            indexRecommend,
                                            1,
                                            'recomendFors'
                                          )
                                        "
                                      >
                                        <div class="select-image">
                                          <span v-if="!data.image"
                                            >Select image</span
                                          >
                                          <validation-provider
                                            v-slot="{ errors }"
                                            style="width: 100%"
                                            name="Image"
                                            rules="required"
                                          >
                                            <b-img
                                              v-if="data.image"
                                              v-model="
                                                arrayDescription[index - 1]
                                                  .recomendFors[indexRecommend]
                                                  .image
                                              "
                                              fluid
                                              :src="data.image.link"
                                              style="
                                                height: 10rem;
                                                width: 20rem;
                                              "
                                            />

                                            <small class="text-danger">{{
                                              errors[0]
                                            }}</small>
                                          </validation-provider>
                                        </div>
                                      </b-col>
                                      <b-col
                                        cols="2"
                                        class="back-sub-element"
                                        @click="
                                          removeElemen(
                                            index - 1,
                                            indexRecommend,
                                            'myRecommended' + index,
                                            'recomendFors'
                                          )
                                        "
                                      >
                                        <div class="sub-element">-</div>
                                      </b-col>
                                    </b-row>
                                    <b-row class="mt-1">
                                      <validation-provider
                                        v-slot="{ errors }"
                                        style="width: 100%"
                                        name="Description"
                                        rules="required"
                                      >
                                        <b-form-input
                                          id="product-add-Summary"
                                          v-model="
                                            arrayDescription[index - 1]
                                              .recomendFors[indexRecommend]
                                              .description
                                          "
                                        />
                                        <small class="text-danger">{{
                                          errors[0]
                                        }}</small>
                                      </validation-provider>
                                    </b-row>
                                  </b-col>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div
                                  style="
                                    height: 100%;
                                    justify-content: center;
                                    align-items: center;
                                    width: 100%;
                                    display: flex;
                                  "
                                >
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('myRecommended' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('myRecommended' + index)"
                              />
                            </swiper>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="1"
                          style="padding: 1rem"
                          @click="
                            appendElemen(
                              index - 1,
                              'myRecommended' + index,
                              'recomendFors'
                            )
                          "
                        >
                          <div class="add-element">+</div>
                        </b-col>
                      </b-row>
                      <!-- Issues it solves -->
                      <b-row>
                        <b-col
                          cols="11"
                          style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                          "
                        >
                          <b-form-group
                            label="Issues it solves"
                            label-for="product-recommended"
                          >
                            <swiper
                              :ref="'issueSwiper' + index"
                              style="
                                border: solid 1px;
                                border-color: #b8c2cc;
                                border-radius: 1rem;
                                padding: 1rem;
                              "
                              class="swiper-multiple"
                              :options="swiperOptions"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :style="
                                !arrayDescription[index - 1].issueItFor.length
                                  ? 'height: 16rem;'
                                  : ''
                              "
                            >
                              <template
                                v-if="
                                  arrayDescription[index - 1].issueItFor.length
                                "
                              >
                                <swiper-slide
                                  v-for="(data, indexIssue) in arrayDescription[
                                    index - 1
                                  ].issueItFor"
                                  :key="indexIssue + 'issue' + index"
                                >
                                  <b-col>
                                    <b-row
                                      style="
                                        border: dashed 2px;
                                        border-radius: 1rem;
                                        border-color: #db536a;
                                        cursor: pointer;
                                        height: 10rem;
                                        justify-content: center;
                                      "
                                    >
                                      <b-col
                                        cols="10"
                                        @click="
                                          openSetImageModal(
                                            index - 1,
                                            indexIssue,
                                            2,
                                            'issueItFor'
                                          )
                                        "
                                      >
                                        <div class="select-image">
                                          <span v-if="!data.image"
                                            >Select image</span
                                          >
                                          <validation-provider
                                            v-slot="{ errors }"
                                            style="width: 100%"
                                            name="Image"
                                            rules="required"
                                          >
                                            <b-img
                                              v-if="data.image"
                                              v-model="
                                                arrayDescription[index - 1]
                                                  .issueItFor[indexIssue].image
                                              "
                                              fluid
                                              :src="data.image.link"
                                              style="
                                                height: 10rem;
                                                width: 20rem;
                                              "
                                            />

                                            <small class="text-danger">{{
                                              errors[0]
                                            }}</small>
                                          </validation-provider>
                                        </div>
                                      </b-col>
                                      <b-col
                                        cols="2"
                                        class="back-sub-element"
                                        @click="
                                          removeElemen(
                                            index - 1,
                                            indexIssue,
                                            'issueSwiper' + index,
                                            'issueItFor'
                                          )
                                        "
                                      >
                                        <div class="sub-element">-</div>
                                      </b-col>
                                    </b-row>
                                    <b-row class="mt-1">
                                      <validation-provider
                                        v-slot="{ errors }"
                                        style="width: 100%"
                                        name="Description"
                                        rules="required"
                                      >
                                        <b-form-input
                                          id="product-add-Summary"
                                          v-model="
                                            arrayDescription[index - 1]
                                              .issueItFor[indexIssue]
                                              .description
                                          "
                                        />
                                        <small class="text-danger">{{
                                          errors[0]
                                        }}</small>
                                      </validation-provider>
                                    </b-row>
                                  </b-col>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div
                                  style="
                                    height: 100%;
                                    justify-content: center;
                                    align-items: center;
                                    width: 100%;
                                    display: flex;
                                  "
                                >
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('issueSwiper' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('issueSwiper' + index)"
                              />
                            </swiper>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="1"
                          style="padding: 1rem"
                          @click="
                            appendElemen(
                              index - 1,
                              'issueSwiper' + index,
                              'issueItFor'
                            )
                          "
                        >
                          <div class="add-element">+</div>
                        </b-col>
                      </b-row>
                      <!-- Benefits -->
                      <b-row>
                        <b-col
                          cols="11"
                          style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                          "
                        >
                          <b-form-group
                            label="Benefits"
                            label-for="product-benefits"
                          >
                            <swiper
                              :ref="'benefit' + index"
                              style="
                                border: solid 1px;
                                border-color: #b8c2cc;
                                border-radius: 1rem;
                                padding: 1rem;
                              "
                              class="swiper-multiple"
                              :options="swiperOptionsBenefits"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :style="
                                !arrayDescription[index - 1].benefits.length
                                  ? 'height: 16rem;'
                                  : ''
                              "
                            >
                              <template
                                v-if="
                                  arrayDescription[index - 1].benefits.length
                                "
                              >
                                <swiper-slide
                                  v-for="(
                                    data, indexBenefit
                                  ) in arrayDescription[index - 1].benefits"
                                  :key="indexBenefit + 'benefit' + index"
                                >
                                  <b-row>
                                    <b-col
                                      cols="4"
                                      style="
                                        border: dashed 2px;
                                        border-radius: 1rem;
                                        border-color: #db536a;
                                        cursor: pointer;
                                      "
                                      @click="
                                        openSetImageModal(
                                          index - 1,
                                          indexBenefit,
                                          3,
                                          'benefits'
                                        )
                                      "
                                    >
                                      <div class="select-image">
                                        <span v-if="!data.image"
                                          >Select image</span
                                        >
                                        <b-img
                                          v-if="data.image"
                                          v-model="
                                            arrayDescription[index - 1]
                                              .benefits[indexBenefit].image
                                          "
                                          fluid
                                          :src="data.image.link"
                                          style="height: 10rem; width: 20rem"
                                        />
                                      </div>
                                    </b-col>
                                    <b-col cols="7">
                                      <b-form-group label="Title">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          style="width: 100%"
                                          name="Description"
                                          rules="required"
                                        >
                                          <b-form-input
                                            id="product-add-Summary"
                                            v-model="
                                              arrayDescription[index - 1]
                                                .benefits[indexBenefit].title
                                            "
                                          />

                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                      <b-form-group label="Content">
                                        <div
                                          v-for="(
                                            itemContent, indexContent
                                          ) in arrayDescription[index - 1]
                                            .benefits[indexBenefit].content"
                                          class="mb-1"
                                          :key="indexContent"
                                        >
                                          <validation-provider
                                            v-slot="{ errors }"
                                            style="width: 100%"
                                            name="Content"
                                            rules="required"
                                          >
                                            <div
                                              class="d-flex"
                                              style="
                                                justify-content: center;
                                                align-items: center;
                                              "
                                            >
                                              <div style="width: 100%">
                                                <b-form-input
                                                  id="product-add-Summary"
                                                  v-model="
                                                    arrayDescription[index - 1]
                                                      .benefits[indexBenefit]
                                                      .content[indexContent]
                                                  "
                                                />
                                                <small class="text-danger">{{
                                                  errors[0]
                                                }}</small>
                                              </div>
                                              <feather-icon
                                                v-if="indexContent === 0"
                                                size="16"
                                                icon="PlusCircleIcon"
                                                class="cursor-pointer ml-1"
                                                @click="
                                                  addContentForm(
                                                    index - 1,
                                                    'benefits',
                                                    indexBenefit,
                                                    'benefit' + index
                                                  )
                                                "
                                              />
                                              <feather-icon
                                                v-else
                                                size="16"
                                                icon="XCircleIcon"
                                                class="cursor-pointer ml-1"
                                                @click="
                                                  removeContentForm(
                                                    index - 1,
                                                    'benefits',
                                                    indexBenefit,
                                                    indexContent,
                                                    'benefit' + index
                                                  )
                                                "
                                              />
                                            </div>
                                          </validation-provider>
                                        </div>
                                      </b-form-group>
                                    </b-col>
                                    <b-col
                                      cols="1"
                                      class="back-sub-element"
                                      @click="
                                        removeElemen(
                                          index - 1,
                                          indexBenefit,
                                          'benefit' + index,
                                          'benefits'
                                        )
                                      "
                                    >
                                      <div class="sub-element">-</div>
                                    </b-col>
                                  </b-row>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div
                                  style="
                                    height: 100%;
                                    justify-content: center;
                                    align-items: center;
                                    width: 100%;
                                    display: flex;
                                  "
                                >
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('benefit' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('benefit' + index)"
                              />
                            </swiper>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="1"
                          style="padding: 1rem"
                          @click="
                            appendElemen(
                              index - 1,
                              'benefit' + index,
                              'benefits'
                            )
                          "
                        >
                          <div class="add-element">+</div>
                        </b-col>
                      </b-row>
                      <!-- PwC Service -->
                      <b-row>
                        <b-col
                          cols="11"
                          style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                          "
                        >
                          <b-form-group
                            label="PwC Service"
                            label-for="product-benefits"
                          >
                            <swiper
                              :ref="'service' + index"
                              style="
                                border: solid 1px;
                                border-color: #b8c2cc;
                                border-radius: 1rem;
                                padding: 1rem;
                              "
                              class="swiper-multiple"
                              :options="swiperOptionsBenefits"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :style="
                                !arrayDescription[index - 1].pwcServices.length
                                  ? 'height: 16rem;'
                                  : ''
                              "
                            >
                              <template
                                v-if="
                                  arrayDescription[index - 1].pwcServices.length
                                "
                              >
                                <swiper-slide
                                  v-for="(
                                    data, indexService
                                  ) in arrayDescription[index - 1].pwcServices"
                                  :key="indexService + 'service' + index"
                                >
                                  <b-row>
                                    <b-col
                                      cols="4"
                                      style="
                                        border: dashed 2px;
                                        border-radius: 1rem;
                                        border-color: #db536a;
                                        cursor: pointer;
                                      "
                                      @click="
                                        openSetImageModal(
                                          index - 1,
                                          indexService,
                                          4,
                                          'pwcServices'
                                        )
                                      "
                                    >
                                      <div class="select-image">
                                        <span v-if="!data.image"
                                          >Select image</span
                                        >
                                        <b-img
                                          v-if="data.image"
                                          v-model="
                                            arrayDescription[index - 1]
                                              .pwcServices[indexService].image
                                          "
                                          fluid
                                          :src="data.image.link"
                                          style="height: 10rem; width: 20rem"
                                        />
                                      </div>
                                    </b-col>
                                    <b-col cols="7">
                                      <b-form-group label="Title">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          style="width: 100%"
                                          name="Title"
                                          rules="required"
                                        >
                                          <b-form-input
                                            id="product-add-Summary"
                                            v-model="
                                              arrayDescription[index - 1]
                                                .pwcServices[indexService].title
                                            "
                                          />

                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                      <b-form-group label="Description">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          style="width: 100%"
                                          name="Description"
                                          rules="required"
                                        >
                                          <b-form-textarea
                                            v-model="
                                              arrayDescription[index - 1]
                                                .pwcServices[indexService]
                                                .description
                                            "
                                            id="textarea-default"
                                            placeholder="Textarea"
                                            rows="2"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                      <b-form-group label="Content">
                                        <div
                                          v-for="(
                                            itemContent, indexContent
                                          ) in arrayDescription[index - 1]
                                            .pwcServices[indexService].content"
                                          class="mb-1"
                                          :key="indexContent"
                                        >
                                          <div
                                            class="d-flex"
                                            style="
                                              justify-content: center;
                                              align-items: center;
                                            "
                                          >
                                            <div style="width: 100%">
                                              <b-form-input
                                                id="product-add-Summary"
                                                v-model="
                                                  arrayDescription[index - 1]
                                                    .pwcServices[indexService]
                                                    .content[indexContent]
                                                "
                                              />
                                            </div>
                                            <feather-icon
                                              v-if="indexContent === 0"
                                              size="16"
                                              icon="PlusCircleIcon"
                                              class="cursor-pointer ml-1"
                                              @click="
                                                addContentForm(
                                                  index - 1,
                                                  'pwcServices',
                                                  indexService,
                                                  'service' + index
                                                )
                                              "
                                            />
                                            <feather-icon
                                              v-else
                                              size="16"
                                              icon="XCircleIcon"
                                              class="cursor-pointer ml-1"
                                              @click="
                                                removeContentForm(
                                                  index - 1,
                                                  'pwcServices',
                                                  indexService,
                                                  indexContent,
                                                  'service' + index
                                                )
                                              "
                                            />
                                          </div>
                                        </div>
                                      </b-form-group>
                                    </b-col>
                                    <b-col
                                      cols="1"
                                      class="back-sub-element"
                                      @click="
                                        removeElemen(
                                          index - 1,
                                          indexService,
                                          'service' + index,
                                          'pwcServices'
                                        )
                                      "
                                    >
                                      <div class="sub-element">-</div>
                                    </b-col>
                                  </b-row>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div
                                  style="
                                    height: 100%;
                                    justify-content: center;
                                    align-items: center;
                                    width: 100%;
                                    display: flex;
                                  "
                                >
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('service' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('service' + index)"
                              />
                            </swiper>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="1"
                          style="padding: 1rem"
                          @click="
                            appendElemen(
                              index - 1,
                              'service' + index,
                              'pwcServices'
                            )
                          "
                        >
                          <div class="add-element">+</div>
                        </b-col>
                      </b-row>
                      <!-- PwC Teams -->
                      <b-row>
                        <b-col
                          cols="11"
                          style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                          "
                        >
                          <b-form-group
                            label="PwC Teams"
                            label-for="product-speaker"
                          >
                            <swiper
                              :ref="'team' + index"
                              style="
                                border: solid 1px;
                                border-color: #b8c2cc;
                                border-radius: 1rem;
                                padding: 1rem;
                              "
                              class="swiper-multiple"
                              :options="swiperOptions"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :style="
                                !arrayDescription[index - 1].pwcTeams.length
                                  ? 'height: 16rem;'
                                  : ''
                              "
                            >
                              <template
                                v-if="
                                  arrayDescription[index - 1].pwcTeams.length
                                "
                              >
                                <swiper-slide
                                  v-for="(data, indexTeam) in arrayDescription[
                                    index - 1
                                  ].pwcTeams"
                                  :key="indexTeam + 'team' + index"
                                >
                                  <b-col>
                                    <b-row
                                      style="
                                        border: dashed 2px;
                                        border-radius: 1rem;
                                        border-color: #db536a;
                                        cursor: pointer;
                                        height: 10rem;
                                        justify-content: center;
                                      "
                                    >
                                      <b-col
                                        cols="10"
                                        @click="
                                          openSetImageModal(
                                            index - 1,
                                            indexTeam,
                                            5,
                                            'pwcTeams'
                                          )
                                        "
                                      >
                                        <div class="select-image">
                                          <span v-if="!data.image"
                                            >Select image</span
                                          >
                                          <b-img
                                            v-if="data.image"
                                            fluid
                                            :src="data.image.link"
                                            style="height: 10rem; width: 20rem"
                                          />
                                        </div>
                                      </b-col>
                                      <b-col
                                        cols="2"
                                        class="back-sub-element"
                                        @click="
                                          removeElemen(
                                            index - 1,
                                            indexTeam,
                                            'team' + index,
                                            'pwcTeams'
                                          )
                                        "
                                      >
                                        <div class="sub-element">-</div>
                                      </b-col>
                                    </b-row>
                                    <b-row class="mt-1">
                                      <b-col md="6">
                                        <b-form-group
                                          label="Name"
                                          label-for="product-speaker-name"
                                          class="mb-2"
                                        >
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="Name"
                                            rules="required"
                                          >
                                            <b-form-input
                                              id="product-speaker-name"
                                              v-model="
                                                arrayDescription[index - 1]
                                                  .pwcTeams[indexTeam].name
                                              "
                                            />
                                            <small class="text-danger">{{
                                              errors[0]
                                            }}</small>
                                          </validation-provider>
                                        </b-form-group>
                                      </b-col>
                                      <b-col md="6">
                                        <b-form-group
                                          label="Role"
                                          label-for="product-speaker-role"
                                          class="mb-2"
                                        >
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="Role"
                                            rules="required"
                                          >
                                            <b-form-input
                                              id="product-speaker-role"
                                              v-model="
                                                arrayDescription[index - 1]
                                                  .pwcTeams[indexTeam].role
                                              "
                                            />
                                            <small class="text-danger">{{
                                              errors[0]
                                            }}</small>
                                          </validation-provider>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div
                                  style="
                                    height: 100%;
                                    justify-content: center;
                                    align-items: center;
                                    width: 100%;
                                    display: flex;
                                  "
                                >
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('team' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('team' + index)"
                              />
                            </swiper>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="1"
                          style="padding: 1rem"
                          @click="
                            appendElemen(index - 1, 'team' + index, 'pwcTeams')
                          "
                        >
                          <div class="add-element">+</div>
                        </b-col>
                      </b-row>
                      <!-- PwC Team Over all -->
                      <b-col cols="12">
                        <b-form-group
                          label="PwC Team Over all"
                          label-for="product-benefits"
                        >
                          <b-form-textarea
                            v-model="arrayDescription[index - 1].teamOverAll"
                            id="textarea-default"
                            placeholder="Textarea"
                            rows="3"
                          />
                        </b-form-group>
                      </b-col>
                    </div>
                  </b-col>
                </b-card-body> </transition-group
            ></b-col>

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="ml-1"
                size="sm"
                variant="primary"
                @click="addNewItemInItemFormDescription"
              >
                Add description form
              </b-button>
            </b-col>
            <!-- Related resources-->
            <b-col
              v-if="productEdit.relatedSource"
              :key="componentKey"
              cols="12"
              class="mt-2"
            >
              <b-row>
                <b-col cols="11">
                  <b-form-group
                    label="Related resources"
                    label-for="product-benefits"
                  >
                    <swiper
                      v-if="!productEdit.relatedSource.length"
                      style="
                        border: solid 1px;
                        border-color: #b8c2cc;
                        border-radius: 1rem;
                        padding: 1rem;
                      "
                      class="swiper-multiple"
                      :options="swiperOptionsBenefits"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      <swiper-slide
                        v-for="(data, indexRelated) in 1"
                        :key="indexRelated + 'related'"
                      >
                        <b-row style="justify-content: center">
                          <b-col
                            cols="6"
                            style="
                              border: solid 2px;
                              border-radius: 1rem;
                              border-color: #db536a;
                            "
                          >
                            <div class="related-source" style="height: 12rem">
                              <span v-if="!data.image"
                                >No offering added yet</span
                              >
                            </div>
                          </b-col>
                        </b-row>
                      </swiper-slide>
                      <div slot="button-next" class="swiper-button-next" />
                      <div slot="button-prev" class="swiper-button-prev" />
                    </swiper>
                    <swiper
                      v-else
                      ref="relatedSource"
                      style="
                        border: solid 1px;
                        border-color: #b8c2cc;
                        border-radius: 1rem;
                        padding: 1rem;
                      "
                      class="swiper-multiple"
                      :options="swiperOptionsBenefits"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      <swiper-slide
                        v-for="(
                          data, indexRelated
                        ) in productEdit.relatedSource"
                        :key="indexRelated + 'related'"
                      >
                        <b-row style="justify-content: center">
                          <b-col
                            cols="6"
                            style="
                              border: solid 2px;
                              border-top-left-radius: 1rem;
                              border-bottom-left-radius: 1rem;
                              border-color: #db536a;
                            "
                          >
                            <div class="mt-2 font-weight-bold mb-2">
                              {{ data.title }}
                            </div>
                            <div
                              class="related-source"
                              style="height: 15rem; margin-bottom: 1rem"
                            >
                              <b-img
                                fluid
                                v-if="data.images.length > 0"
                                :src="
                                  data.images.find(
                                    (x) => x.type === 'header_image'
                                  )
                                    ? data.images.find(
                                        (x) => x.type === 'header_image'
                                      ).link
                                    : ''
                                "
                                style="height: 15rem"
                              />
                            </div>
                          </b-col>
                          <b-col
                            cols="1"
                            class="back-sub-element"
                            @click="
                              removeElementRelated(
                                indexRelated,
                                'relatedSource'
                              )
                            "
                          >
                            <div class="sub-element">-</div>
                          </b-col>
                        </b-row>
                      </swiper-slide>
                      <div
                        slot="button-next"
                        class="swiper-button-next"
                        @click="nextSlideRelated('relatedSource')"
                      />
                      <div
                        slot="button-prev"
                        class="swiper-button-prev"
                        @click="previousSlideRelated('relatedSource')"
                      />
                    </swiper>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="1"
                  style="padding: 1rem"
                  @click="isShowModalSelectRelate = !isShowModalSelectRelate"
                >
                  <div class="add-element">+</div>
                </b-col>
              </b-row>
            </b-col>
            <!-- Similar asset-->
            <b-col :key="componentKey" cols="12" class="mt-2">
              <b-row>
                <b-col cols="11">
                  <b-form-group
                    label="Similar asset"
                    label-for="product-benefits"
                  >
                    <swiper
                      v-if="
                        productEdit.smilarAssets &&
                        !productEdit.smilarAssets.length
                      "
                      style="
                        border: solid 1px;
                        border-color: #b8c2cc;
                        border-radius: 1rem;
                        padding: 1rem;
                      "
                      class="swiper-multiple"
                      :options="swiperOptionsBenefits"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      <swiper-slide
                        v-for="(data, indexRelated) in 1"
                        :key="indexRelated + 'related'"
                      >
                        <b-row style="justify-content: center">
                          <b-col
                            cols="6"
                            style="
                              border: solid 2px;
                              border-radius: 1rem;
                              border-color: #db536a;
                            "
                          >
                            <div class="related-source" style="height: 12rem">
                              <span v-if="!data.image"
                                >No offering added yet</span
                              >
                            </div>
                          </b-col>
                        </b-row>
                      </swiper-slide>
                      <div slot="button-next" class="swiper-button-next" />
                      <div slot="button-prev" class="swiper-button-prev" />
                    </swiper>
                    <swiper
                      v-else
                      ref="similarAsset"
                      style="
                        border: solid 1px;
                        border-color: #b8c2cc;
                        border-radius: 1rem;
                        padding: 1rem;
                      "
                      class="swiper-multiple"
                      :options="swiperOptionsBenefits"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      <swiper-slide
                        v-for="(data, indexRelated) in productEdit.smilarAssets"
                        :key="indexRelated + 'related'"
                      >
                        <b-row style="justify-content: center">
                          <b-col
                            cols="6"
                            style="
                              border: solid 2px;
                              border-top-left-radius: 1rem;
                              border-bottom-left-radius: 1rem;
                              border-color: #db536a;
                            "
                          >
                            <div class="mt-2 font-weight-bold mb-2">
                              {{ data.title }}
                            </div>
                            <div
                              class="related-source"
                              style="height: 15rem; margin-bottom: 1rem"
                            >
                              <b-img
                                fluid
                                v-if="data.images.length > 0"
                                :src="
                                  data.images.find(
                                    (x) => x.type === 'header_image'
                                  )
                                    ? data.images.find(
                                        (x) => x.type === 'header_image'
                                      ).link
                                    : ''
                                "
                                style="height: 15rem"
                              />
                            </div>
                          </b-col>
                          <b-col
                            cols="1"
                            class="back-sub-element"
                            @click="
                              removeElementRelated(indexRelated, 'smilarAssets')
                            "
                          >
                            <div class="sub-element">-</div>
                          </b-col>
                        </b-row>
                      </swiper-slide>
                      <div
                        slot="button-next"
                        class="swiper-button-next"
                        @click="nextSlideRelated('similarAsset')"
                      />
                      <div
                        slot="button-prev"
                        class="swiper-button-prev"
                        @click="previousSlideRelated('similarAsset')"
                      />
                    </swiper>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="1"
                  style="padding: 1rem"
                  @click="isShowModalSelectSimilar = !isShowModalSelectSimilar"
                >
                  <div class="add-element">+</div>
                </b-col>
              </b-row>
            </b-col>
            <b-card-body class="mb-2 pb-2 w-100">
              <b-col cols="12" class="mb-2">
                <div class="border rounded p-2 pb-0">
                  <h4 class="mb-1">Featured Image</h4>
                  <!-- Asset overview -->
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label="Asset overview"
                        label-for="product-add-region"
                        class="mb-2"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Asset overview"
                          rules="required"
                        >
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="primary"
                            @click="
                              isShowModalSelectImageOverview =
                                !isShowModalSelectImageOverview
                            "
                          >
                            Select image
                          </b-button>
                          <div class="border rounded p-2 pb-0 mt-2">
                            <h6 v-if="imageOverview.length === 0">
                              No photos added yet
                            </h6>
                            <b-img
                              v-else
                              v-model="imageOverview"
                              center
                              fluid
                              :src="imageOverview[0].link"
                              height="100"
                            />
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- Header images -->
                    <b-col md="6">
                      <b-form-group
                        label="Header images"
                        label-for="product-add-region"
                        class="mb-2"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Header images"
                          rules="required"
                        >
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="primary"
                            @click="
                              isShowModalSelectImage = !isShowModalSelectImage
                            "
                          >
                            Select image
                          </b-button>
                          <div class="border rounded p-2 pb-0 mt-2">
                            <h6 v-if="imageHeader.length === 0">
                              No photos added yet
                            </h6>
                            <b-img
                              v-else
                              v-model="imageHeader"
                              center
                              fluid
                              :src="imageHeader[0].link"
                              height="100"
                            />
                          </div>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- How it works medias -->
                    <b-col md="6">
                      <b-form-group
                        label="How it works medias"
                        label-for="product-add-title"
                        class="mb-2"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="How it works medias"
                          rules=""
                        >
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="primary"
                            @click="
                              isShowModalSelectImages = !isShowModalSelectImages
                            "
                          >
                            Select images
                          </b-button>
                          <div class="border rounded p-2 pb-0 mt-2">
                            <h6 v-if="imagesHowItWork.length === 0">
                              No photos added yet
                            </h6>
                            <swiper
                              v-if="imagesHowItWork.length > 0"
                              v-model="imagesHowItWork"
                              class="swiper-multiple"
                              style="margin-left: 1rem; width: 48"
                              :options="swiperOptionsHowItWork"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                            >
                              <swiper-slide
                                v-for="media in imagesHowItWork"
                                :key="media.id"
                              >
                                <b-link>
                                  <div class="img-container">
                                    <b-img
                                      v-if="media.media_type === 'image'"
                                      fluid
                                      :src="media.link"
                                      style="height: 10rem; width: 20rem"
                                    />
                                    <div
                                      v-else
                                      style="
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;
                                      "
                                    >
                                      <b-embed type="video">
                                        <source
                                          :src="media.link"
                                          type="video/mp4"
                                        />
                                      </b-embed>
                                    </div></div
                                ></b-link>
                              </swiper-slide>
                            </swiper>
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-card-body>

            <b-col cols="12">
              <b-button
                v-if="!idProduct"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="confirmProduct"
              >
                Add Asset
              </b-button>
              <b-button
                v-if="idProduct"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="confirmProduct"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :to="{ name: 'asset-manager' }"
                variant="outline-secondary"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!--/ form -->
      </validation-observer>
      <select-image-modal
        :is-show-modal-select-image.sync="isShowModalSelectImageOverview"
        :selected-box="imageHeader"
        :region="productEdit.region_id"
        @set-image-id="setImageIdOverview"
      />
      <select-image-modal
        :is-show-modal-select-image.sync="isShowModalSelectImage"
        :selected-box="imageHeader"
        :region="productEdit.region_id"
        @set-image-id="setImageId"
      />
      <select-images-modal
        :is-show-modal-select-images.sync="isShowModalSelectImages"
        :selected-box="imageHeader"
        :region="productEdit.region_id"
        @set-images-id="setImagesId"
      />
      <select-image-modal
        :is-show-modal-select-image.sync="isShowModalRefType"
        :selected-box="imageHeader"
        :region="productEdit.region_id"
        @set-image-id="setImageRef"
      />
      <select-related-asset
        :is-show-modal-select-related-asset.sync="isShowModalSelectRelate"
        :region="productEdit.region_id"
        @set-asset-related="setAssetRelated"
      />
      <select-similar-asset
        :is-show-modal-select-similar-asset.sync="isShowModalSelectSimilar"
        offering-type="asset"
        :region="productEdit.region_id"
        @set-asset-similar="setAssetSimilar"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import {
  BImg,
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BLink,
  BCardBody,
  BOverlay,
  BEmbed,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
// import { QuillEditor } from '@vueup/vue-quill'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
// eslint-disable-next-line import/no-cycle
import store from "@/store";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import * as config from "@/utils/config";
// eslint-disable-next-line import/no-cycle
import { ref } from "@vue/composition-api";
import SelectImageModal from "@/views/modal/SelectImageModal.vue";
import SelectImagesModal from "@/views/modal/SelectImagesModal.vue";
import SelectRelatedAsset from "@/views/modal/selectRelatedAsset/SelectRelatedAsset.vue";
import SelectSimilarAsset from "@/views/modal/selectSimilarAsset/SelectSimilarAsset.vue";
import * as utils from "@/utils/utils";
import editProduct from "./detailProduct";
import "swiper/swiper-bundle.css";

export default {
  components: {
    BEmbed,
    BImg,
    SelectImageModal,
    SelectImagesModal,
    Swiper,
    SwiperSlide,
    BCardBody,
    BLink,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    SelectRelatedAsset,
    SelectSimilarAsset,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters("app", ["regionOptions", "languageOptions"]),
    ...mapGetters("product", ["productCategory"]),
    ...mapGetters("product", ["productTags"]),
    ...mapGetters("product", ["productType"]),
    ...mapGetters("bussiness", ["productBussiess"]),
    ...mapGetters("role", ["productRole"]),
  },
  data() {
    return {
      componentKey: 0,
      active: "Active",
      inactive: "Inactive",
      snowOption: {
        placeholder: "",
        theme: "snow",
      },
      readOnlyOption: {
        placeholder: "Please choose region",
        theme: "snow",
      },
      required,
      swiperOptionsBenefits: {
        allowTouchMove: false,
        centeredSlides: true,
        slidesPerView: 2,
        spaceBetween: 40,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptions: {
        allowTouchMove: false,
        centeredSlides: true,
        slidesPerView: 4,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      currentIndex: null,
      currentIndexRef: null,
      currentImageKey: "",
      refType: 0,
    };
  },
  async created() {
    this.loading = true;
    await store.dispatch("product/fetchTags");
    this.loading = false;
  },
  setup() {
    const isShowModalRefType = ref(false);
    const isShowModalSelectImage = ref(false);
    const isShowModalSelectImages = ref(false);
    const isShowModalSelectImageOverview = ref(false);
    const isShowModalSelectRelate = ref(false);
    const isShowModalSelectSimilar = ref(false);
    const swiperOptionsHowItWork = {
      slidesPerView: 3,
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    };
    const swiperOptions = {
      slidesPerView: 5,
      spaceBetween: 55,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1600: {
          slidesPerView: 4,
          spaceBetween: 55,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 55,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 55,
        },
      },
    };
    const {
      imageHeader,
      imagesHowItWork,
      imageOverview,
      productEdit,
      images,
      categogies,
      idProduct,
      fromPrice,
      toPrice,
      statusProduct,
      arrayDescription,
      count,
      arrayRegion,
      countRegion,
      updateItemForm,
      categorySelected,
      imagesId,
      loading,
    } = editProduct();
    return {
      swiperOptions,
      config,
      isShowModalSelectImage,
      isShowModalSelectImages,
      isShowModalSelectImageOverview,
      imageHeader,
      imagesHowItWork,
      imageOverview,
      productEdit,
      images,
      categogies,
      idProduct,
      fromPrice,
      toPrice,
      statusProduct,
      arrayDescription,
      count,
      arrayRegion,
      countRegion,
      updateItemForm,
      categorySelected,
      imagesId,
      loading,
      isShowModalRefType,
      swiperOptionsHowItWork,
      isShowModalSelectRelate,
      isShowModalSelectSimilar,
    };
  },

  methods: {
    async addContentForm(index, ref, subIndex, refIndex) {
      await this.arrayDescription[index][ref][subIndex].content.push("");
      this.forceUpdate();
      await this.$refs[refIndex][0].$swiper.slideTo(subIndex + 1, 1);
      console.log(this.$refs[refIndex][0].$swiper.slideTo(subIndex + 1, 1));
    },
    async removeContentForm(index, ref, subIndex, indexItem, refIndex) {
      await this.arrayDescription[index][ref][subIndex].content.splice(
        indexItem,
        1
      );
      this.forceUpdate();
      await this.$refs[refIndex][0].$swiper.slideTo(subIndex + 1, 1);
      console.log(this.$refs[refIndex][0].$swiper.slideTo(subIndex + 1, 1));
    },
    async removeElementRelated(indexService, refIndex) {
      this.productEdit[refIndex].splice(indexService, 1);
      await this.forceUpdate();
      await this.$refs[refIndex].$swiper.slideTo(indexService - 1, 1);
    },
    setAssetSimilar(arrAsset) {
      this.productEdit.smilarAssets = arrAsset;
    },
    setAssetRelated(arrAsset) {
      this.productEdit.relatedSource = arrAsset;
    },
    async nextSlideRelated(refIndex) {
      console.log(this.$refs[refIndex]);
      await this.$refs[refIndex].$swiper.slideNext();
    },
    async previousSlideRelated(refIndex) {
      await this.$refs[refIndex].$swiper.slidePrev();
    },
    async nextSlide(refIndex) {
      console.log(this.$refs[refIndex][0].$swiper);
      await this.$refs[refIndex][0].$swiper.slideNext();
    },
    async previousSlide(refIndex) {
      await this.$refs[refIndex][0].$swiper.slidePrev();
    },
    openSetImageModal(index, indexRef, type, key) {
      this.currentIndex = index;
      this.currentIndexRef = indexRef;
      this.refType = type;
      this.isShowModalRefType = true;
      this.currentImageKey = key;
    },
    setImageIdOverview(arrayImage) {
      this.imageOverview = arrayImage;
    },
    setImageRef(imageArray) {
      this.arrayDescription[this.currentIndex][this.currentImageKey][
        this.currentIndexRef
      ].image = imageArray[0];
    },
    forceUpdate() {
      this.componentKey += 1;
    },
    async appendElemen(index, refIndex, key) {
      if (key === "benefits") {
        console.log(key);
        await this.arrayDescription[index][key].push({
          image: null,
          title: "",
          content: [""],
        });
      } else if (key === "pwcServices") {
        await this.arrayDescription[index][key].push({
          image: null,
          title: "",
          description: "",
          content: [""],
        });
      } else {
        await this.arrayDescription[index][key].push({});
      }
      await this.forceUpdate();
      await this.$refs[refIndex][0].$swiper.slideTo(
        this.arrayDescription[index][key].length,
        1
      );
      console.log(this.arrayDescription);
    },
    async removeElemen(index, indexService, refIndex, key) {
      this.arrayDescription[index][key].splice(indexService, 1);
      await this.forceUpdate();
      await this.$refs[refIndex][0].$swiper.slideTo(indexService - 1, 1);
    },
    onChangeTitle() {
      this.productEdit.slug = utils.toSlug(this.productEdit.title);
    },
    setImageId(arrayID) {
      this.imageHeader = arrayID;
    },
    setImagesId(arrayID) {
      this.imagesHowItWork = arrayID;
    },
    updateProductType(val) {
      this.productEdit.type = val;
    },
    updateProductCategory(val) {
      this.productEdit.category_id = val;
    },
    addNewItemInItemFormDescription() {
      this.arrayDescription.push({
        region_id: "",
        title: "",
        description: "",
        recomendFors: [],
        issueItFor: [],
        benefits: [],
        pwcTeams: [],
        pwcServices: [],
        relatedSource: [],
        smilarAssets: [],
      });
      this.count += 1;
    },
    addNewItemInItemFormRegionPrice() {
      this.arrayDescription.push({
        title: "",
        description: "",
        region_id: "",
      });
      this.count += 1;
    },
    removeItem(index) {
      this.arrayDescription.splice(index, 1);
      this.count -= 1;
    },
    confirmProduct() {
      this.$refs.editProductValidation.validate().then((success) => {
        const arrImage = this.imageHeader.concat(this.imagesHowItWork);
        const arrImageID = arrImage.map((x) => x.id);

        if (success) {
          this.loading = true;
          const productUpdateData = {
            offering_type: "asset",
            title: this.productEdit.title,
            tag_ids: this.productEdit.tag_ids.map((x) => x.value),
            type: this.productEdit.type.value,
            from_price: parseInt(this.productEdit.from_price, 10),
            slug: this.productEdit.slug,
            description: [
              {
                region_id: this.arrayDescription[0].region_id.region,
                title: this.arrayDescription[0].title,
                description: this.arrayDescription[0].description,
                summary: this.arrayDescription[0].summary,
              },
            ],
            metadata: {
              regions_form: this.arrayDescription,
              imageOverview: this.imageOverview[0],
              smilarAssets: this.productEdit.smilarAssets,
              relatedSource: this.productEdit.relatedSource,
            },
            metadata2: {
              properties: this.arrayDescription.map((x) => ({
                region_id: x.region_id,
                title: x.title,
                description: x.description,
                summary: x.summary,
              })),
            },
            product_role_ids: this.productEdit.product_roles.map(
              (x) => x.value
            ),
            product_bussiness_challenge_ids:
              this.productEdit.product_bussiness_challenges.map((x) => x.value),
            category_ids: this.productEdit.category_ids.map((x) => x.value),
            media_ids: arrImageID,
          };
          if (this.productEdit.status === "approved") {
            store
              .dispatch("product/updateProductOnEdit", {
                id: this.idProduct,
                product: productUpdateData,
              })
              .then((response) => {
                if (response.request.status === 201) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Update product successfully",
                      icon: "BellIcon",
                      variant: "success",
                    },
                  });
                  this.productEdit = null;
                  this.arrayDescription = [];
                  this.images = [];
                  this.imagesId = [];
                  this.loading = false;
                  router.push("/asset-manager");
                }
              })
              .catch((e) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `${e.message}`,
                    icon: "BellIcon",
                    variant: "danger",
                  },
                });
                this.loading = false;
              });
          } else {
            store
              .dispatch("product/updateProduct", {
                id: this.idProduct,
                product: productUpdateData,
              })
              .then((response) => {
                if (response.request.status === 200) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Update product successfully",
                      icon: "BellIcon",
                      variant: "success",
                    },
                  });
                  this.productEdit = null;
                  this.arrayDescription = [];
                  this.images = [];
                  this.imagesId = [];
                  this.loading = false;
                  router.push("/asset-manager");
                }
              })
              .catch((e) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `${e.message}`,
                    icon: "BellIcon",
                    variant: "danger",
                  },
                });
                this.loading = false;
              });
          }
        }
      });
    },
    async uploadImageSuccess(formData) {
      await store
        .dispatch("media/updloadMedia", formData)
        .then((response) => {
          this.imagesId.push(response.body.id);
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.message}`,
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    beforeRemove(index, done) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this image.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.imagesId.splice(index, 1);
            done();
          }
        });
    },
    async editImage(formData, index, fileList) {
      await store
        .dispatch("media/updloadMedia", formData)
        .then((response) => {
          this.imagesId[index] = response.body.id;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.message}`,
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
      this.images = [];
      fileList.forEach((e) => {
        if (!this.images.includes(e)) {
          this.images.push(e);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.ql-container {
  min-height: inherit;
}

.select-image {
  flex-direction: column;
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
  border: dash grey;
}

.add-element {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: xx-large;
  background-color: #db536a;
  color: white;
  border-radius: 1rem;
  border-color: #db536a;
  cursor: pointer;
  justify-content: center;
  padding: 2rem;
}

.back-sub-element {
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-top-right-radius: 1rem;
  border-end-end-radius: 1rem;
  background-color: #db536a;
}
.sub-element {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: xx-large;
  background-color: #db536a;
  color: white;
  border-radius: 1rem;
  border-color: #db536a;
  cursor: pointer;
  justify-content: center;
}

.select-image-benefit {
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: dashed 2px;
  border-radius: 1rem;
  border-color: #db536a;
  cursor: pointer;
}
.swiper-button-next {
  color: #db536a;
}
.swiper-button-prev {
  color: #db536a;
}
.related-source {
  flex-direction: column;
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
}
</style>
