import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import * as config from '@/utils/config'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useProductsList(dataMarked) {
  const searchQueryElearn = ref('')
  const toast = useToast()
  const showTable = ref(false)
  const refElearnListTable = ref(null)
  const tableColumnsElearn = [
    { key: 'title', sortable: false },
  ]
  const totalElearns = ref(0)
  const currentPageElearn = ref(1)
  const perPageElearn = ref(10)
  const elearnData = ref([])
  const regionId = router.currentRoute.params.id

  const fetchElearn = regionQuery => {
    if(regionQuery || regionId){
      showTable.value = true
      store
        .dispatch('product/fetchProducts', {
          search: searchQueryElearn.value,
          limit: perPageElearn.value,
          page: currentPageElearn.value,
          region_ids: router.currentRoute.name
            === 'detail-spotlight'
            ? regionId : regionQuery,
          offering_type: 'elearn',
          order_by: 'created_at',
          status: 'approved',
          order_type: 'desc',
        })
        .then(response => {
          // const products = response.body.data
          // callback(products)
          elearnData.value = response.body.data.map(x => ({
            ...x,
            title: `[Elearn] - ${x.title}`,
            checked: false,
          }))
          if (dataMarked) {
            // console.log(dataMarked)
            dataMarked.value.forEach(x => {
              const idx = elearnData.value.findIndex(i => i.id === x?.product?.id)
              if (idx !== -1) {
                elearnData.value[idx].checked = true
              }
            })
          }
          totalElearns.value = response.body.total
          showTable.value = false
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching products list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          showTable.value = false
        })
    }

  }
  const timeout = ref(null)
  if(!dataMarked){
    fetchElearn()
  }
  watch(
    [
      currentPageElearn,
      perPageElearn,
      searchQueryElearn,
    ],
    () => {
      showTable.value = true
      clearTimeout(timeout.value)

      timeout.value = setTimeout(() => {
        fetchElearn()
        showTable.value = false
      }, 600)
    },
  )

  return {
    timeout,
    showTable,
    fetchElearn,
    tableColumnsElearn,
    perPageElearn,
    currentPageElearn,
    totalElearns,
    searchQueryElearn,
    refElearnListTable,
    elearnData,
  }
}
