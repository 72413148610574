import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
import store from '@/store'
import * as config from '@/utils/config'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { forEach } from 'postcss-rtl/lib/affected-props'

export default function useProductsList(dataMarked) {
  const searchQueryAsset = ref('')
  const toast = useToast()
  const showTableAsset = ref(false)
  const refAssetListTable = ref(null)
  const tableColumnsAsset = [
    { key: 'title', sortable: false },
  ]
  const totalAssets = ref(0)
  const currentPageAsset = ref(1)
  const perPageAsset = ref(10)
  const assetData = ref([])
  const regionId = router.currentRoute.params.id
  console.log(router)
  const fetchAssets = regionQuery => {
    if(regionQuery || regionId){
      showTableAsset.value = true
      store
        .dispatch('product/fetchProducts', {
          search: searchQueryAsset.value,
          limit: perPageAsset.value,
          page: currentPageAsset.value,
          region_ids: router.currentRoute.name
            === 'detail-spotlight'
            ? regionId : regionQuery,
          offering_type: 'asset',
          status: 'approved',
          order_by: 'created_at',
          order_type: 'desc',
        })
        .then(response => {
          assetData.value = response.body.data.map(x => ({
            ...x,
            title: `[Asset] - ${x.title}`,
            checked: false,
          }))
          if (dataMarked) {
            // console.log(dataMarked)
            dataMarked.value.forEach(x => {
              const idx = assetData.value.findIndex(i => i.id === x?.product?.id)
              if (idx !== -1) {
                assetData.value[idx].checked = true
              }
            })
          }
          totalAssets.value = response.body.total
          showTableAsset.value = false
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching products list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          showTableAsset.value = false
        })
    }
  
  }
  const timeout = ref(null)
  if(!dataMarked){
    fetchAssets()
  }
  watch(
    [
      currentPageAsset,
      perPageAsset,
      searchQueryAsset,
    ],
    () => {
      showTableAsset.value = true
      clearTimeout(timeout.value)

      timeout.value = setTimeout(() => {
        fetchAssets()
        showTableAsset.value = false
      }, 600)
    },
  )

  return {
    timeout,
    showTableAsset,
    fetchAssets,
    tableColumnsAsset,
    perPageAsset,
    currentPageAsset,
    totalAssets,
    searchQueryAsset,
    refAssetListTable,
    assetData,
  }
}
