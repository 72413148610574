import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import * as config from '@/utils/config'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProductsList(offeringType) {
  const searchQueryAsset = ref('')
  const toast = useToast()
  const showTableAsset = ref(false)
  const refAssetListTable = ref(null)
  const tableColumnsAsset = [
    { key: 'title', sortable: false },
  ]
  const totalAssets = ref(0)
  const currentPageAsset = ref(1)
  const perPageAsset = ref(10)
  const assetData = ref([])
  const fetchAssets = (regionQuery) => {
    if(regionQuery){
      console.log("Assettt****************************")
      showTableAsset.value = true
      store
        .dispatch('product/fetchProducts', {
          search: searchQueryAsset.value,
          limit: perPageAsset.value,
          page: currentPageAsset.value,
          offering_type: offeringType,
          region_ids: regionQuery,
          status: 'approved',
          order_by: 'created_at',
          order_type: 'desc',
        })
        .then(response => {
          assetData.value = response.body.data.map(x => ({
            ...x,
            title: `[Asset] - ${x.title}`,
            checked: false,
          }))
          totalAssets.value = response.body.total
          showTableAsset.value = false
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching products list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          showTableAsset.value = false
        })
    }

  }
  const timeout = ref(null)
  fetchAssets()
  watch(
    [
      currentPageAsset,
      perPageAsset,
      searchQueryAsset,
    ],
    () => {
      showTableAsset.value = true
      clearTimeout(timeout.value)
      console.log("hello")
      timeout.value = setTimeout(() => {
        fetchAssets()
        showTableAsset.value = false
      }, 600)
    },
  )

  return {
    timeout,
    showTableAsset,
    fetchAssets,
    tableColumnsAsset,
    perPageAsset,
    currentPageAsset,
    totalAssets,
    searchQueryAsset,
    refAssetListTable,
    assetData,
  }
}
