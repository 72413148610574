import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function editProduct() {
  const vendorSelected = ref(null)
  const categorySelected = {}
  const idProduct = ref(null)
  const categogies = []
  const images = ref([])
  const imagesId = ref([])
  const imageHeader = ref([])
  const imagesHowItWork = ref([])
  const imageOverview = ref([])

  const arrayDescription = [
    {
      region_id: '',
      title: '',
      description: '',
      recomendFors: [],
      issueItFor: [],
      benefits: [],
      pwcTeams: [],
      pwcServices: [],
      relatedSource: [],
      smilarAssets: [],
    },
  ]
  const arrayRegion = [
    {
      region_id: '',
      from_price: '',
    },
  ]
  const productEdit = ref({
    title: '',
    slug: '',
    tag_ids: [],
    category_id: {},
    type: {},
    form_price: '',
  })
  const fromPrice = ref(null)
  const toPrice = ref(null)
  const statusProduct = ref(null)
  const count = ref(1)
  const countRegion = ref(null)
  const loading = ref(false)
  idProduct.value = router.currentRoute.params.id
  setTimeout(() => {
    loading.value = true
    store
      .dispatch('product/fetchProduct', { id: idProduct.value })
      .then(response => {
        productEdit.value = {
          region_id: response.body.region_id,
          title: response.body.title,
          tag_ids: response.body.tags.map(x => ({
            label: x.name,
            value: x.id,
          })),
          type: {
            label: store.state.product.productType.find(
              o => response.body.type === o.value,
            ).label,
            value: response.body.type,
          },
          status: response.body.status,
          from_price: response.body.price ? response.body.price.from_price : 0,
          slug: response.body.slug,
          category_ids: response.body?.categories?.map(x => ({
            label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
            value: x.id,
          })),
          product_roles: response.body.product_roles.map(x => ({
            label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
            value: x.id,
          })),
          product_bussiness_challenges: response.body.product_bussiness_challenges.map(x => ({
            label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
            value: x.id,
          })),
        }
        //Fetch enities by region
        if(productEdit.value.region_id){
          const queryParams = {
            region_ids: [productEdit.value.region_id],
          }
          store.dispatch('bussiness/fetchBussiness', queryParams)
          store.dispatch('role/fetchRole', queryParams)
          const queryParamsCate = {
            region: productEdit.value.region_id,
          }
          store.dispatch('product/fetchCategories', queryParamsCate)
        }
    
        if (response.body.images.length > 0) {
          // productEdit.value.images = images.value = response.body.images.map(
          //   (x, i) => ({
          //     path: `${x.link}?sp=r&st=2022-12-06T16:32:46Z&se=2023-12-07T00:32:46Z&spr=https&sv=2021-06-08&sr=c&sig=izAnhRtos9EO61CURspF0vPvpis7vs4LNR9DhkNUHa8%3D`,
          //     default: i,
          //     highlight: i,
          //   }),
          // )
          // imagesId.value = response.body.images.map(x => x.id)
          for (let i = 0; i < response.body.images.length; i += 1) {
            if (response.body.images[i].type === 'header_image') {
              imageHeader.value.push(response.body.images[i])
            } else if (response.body.images[i].type === 'how_it_work') {
              imagesHowItWork.value.push(response.body.images[i])
            }
          }
        }
        // array description
        arrayDescription.shift()
        count.value = response.body.metadata.metadata.regions_form.length
        //Modify old data
        response.body.metadata.metadata.regions_form.forEach((e, indexForm) => {
          if(e.benefits.length){
            e.benefits.forEach((i, indexItem) => {
              if (!i.content?.length){
                response.body.metadata.metadata.regions_form[indexForm].benefits[indexItem].content = ['', '', '']
              }
            })
          }
          if(e.pwcServices.length){
            e.pwcServices.forEach((i, indexItem) => {
              if (!i.content?.length){
                response.body.metadata.metadata.regions_form[indexForm].pwcServices[indexItem].content = ['', '', '']
              }
            })
          }
        })
        //Map ui
        response.body.metadata.metadata.regions_form.forEach(e => {
          arrayDescription.push(e)
        })
        // Get image overview
        if(response.body.metadata.metadata.imageOverview){
          imageOverview.value.push(
            response.body.metadata.metadata.imageOverview,
          )
        }
        // Get silmilar asset, related source
        if (response.body.metadata.metadata.smilarAssets) {
          productEdit.value.smilarAssets = response.body.metadata.metadata.smilarAssets
        } else {
          productEdit.value.smilarAssets = []
        }
        if (response.body.metadata.metadata.relatedSource) {
          productEdit.value.relatedSource = response.body.metadata.metadata.relatedSource
        } else {
          productEdit.value.relatedSource = []
        }
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        loading.value = false
      })
  }, 0)

  const updateItemForm = (index, val) => {
    const region_id = val
    arrayDescription[index].region_id = region_id
  }
  return {
    imageHeader,
    imagesHowItWork,
    imageOverview,
    productEdit,
    images,
    categogies,
    idProduct,
    fromPrice,
    toPrice,
    statusProduct,
    arrayDescription,
    count,
    arrayRegion,
    countRegion,
    updateItemForm,
    vendorSelected,
    categorySelected,
    imagesId,
    loading,
  }
}
