<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="select-image-modal"
      centered
      size="sm"
      :visible="isShowModalSelectSimilarAsset"
      title="Select similar offering"
      cancel-title="Close"
      ok-title="Accept"
      cancel-variant="outline-secondary"
      :hide-footer="true"
      @hide="$emit('update:is-show-modal-select-similar-asset', false)"
    >
      <div>
        <b-overlay
    :show="showTableAsset"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
  <b-row>
          <!-- Asset -->
          <b-col cols="12">
            <b-card
              title="Asset"
              class="mb-0"
            >
              <!-- Search -->
              <b-col
                cols="12"
              >
                <div class="">
                  <b-form-input
                    v-model="searchQueryAsset"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
              <div>
                <b-form-checkbox
                  v-for="item,index in assetData"
                  :key="item.id"
                  v-model="assetData[index].checked"
                  class="mt-2"
                  :checked="assetData[index].checked"
                  @input="onChangeCheckBox(item, 'assetData')"
                >
                  {{ item.title }}
                </b-form-checkbox>
              </div>
              <div class="mx-2 mb-2 mt-2">
                <b-row>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="12"
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                  "
                  >
                    <b-pagination
                      v-model="currentPageAsset"
                      :total-rows="totalAssets"
                      :per-page="perPageAsset"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>

          </b-col>
        </b-row>
  </b-overlay>

        <div class="d-flex mt-2">
          <div class="ml-auto ">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="submit"
            >
              Confirm
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="ml-2"
              @click="$emit('update:is-show-modal-select-similar-asset', false)"
            >
              Cancel
            </b-button>
          </div>

        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BTable,
  BPagination,
  BFormInput, BCard, BButton, BFormCheckbox, BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import 'vue-advanced-cropper/dist/style.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAssetList from './assetList'

export default {
  components: {
    BPagination,
    BTable,
    BCol,
    BRow,
    BModal,
    BFormInput,
    BCard,
    BButton,
    BFormCheckbox,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    isShowModalSelectSimilarAsset: {
      type: Boolean,
      required: true,
    },
    offeringType: {
      type: String,
      required: true,
    },
    region: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selectMode: 'multi',
      selected: [],
      loading: false,
      timeout: null,
    }
  },
  watch: {
    async region(value) {
      if (!value) return
      this.fetchAssets(value)
    },
    async searchQueryAsset(value) {
      if (!value) return
      this.timeout = setTimeout(() => {
        this.fetchAssets(this.region)
        this.showTableAsset = false
      }, 600)
    },
    async currentPageAsset(value) {
      if (!value) return
      this.timeout = setTimeout(() => {
        this.fetchAssets(this.region)
        this.showTableAsset = false
      }, 600)
    },
    isShowModalSelectSimilarAsset(value) {
      if (value) {
        this.fetchAssets(this.region)
      }
    },
  },
  setup(props) {
    const assetData = useAssetList(props.offeringType)
    return {
      ...assetData,
    }
  },
  methods: {
    async onChangeCheckBox(data, refTable) {
      if (this.selected.filter(e => e.id === data.id).length > 0) {
        this.selected.splice(this.selected.findIndex(e => e.id === data.id), 1)
      } else {
        if (this.selected.length === 3) {
          const idx = this[refTable].findIndex(item => item === data)
          await this.$nextTick()
          this[refTable][idx].checked = false
          // await this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Choose a maximum of 3 offerings!',
          //     icon: 'BellIcon',
          //     variant: 'danger',
          //   },
          // })
          return
        }
        this.selected.push(data)
      }
    },
    start_and_end(str) {
      if (str) {
        if (str.length > 20) {
          return `${str.substr(0, 10)}...${str.substr(str.length - 10, str.length)}`
        }
        return ''
      }
      return str
    },
    isSelected(index) {
      return (this.selectedImage.includes(index))
    },
    imageCss(imageIndex) {
      const classes = ['selectable']
      if (this.isSelected(imageIndex)) { // Add "active" if selected
        classes.push('active_box')
      }
      return classes
    },
    onImageSelect(imageIndex) {
      this.selectedImage = []
      this.selectedImage.push(imageIndex)
    },
    resetModal() {
      this.selectedImage = []
      this.selected = []
      this.fetchAssets()
    },
    submit() {
      if (this.selected.length > 3 || this.selected.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Choose a maximum of 3 offerings!',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.$emit('set-asset-similar', this.selected)
      this.selected = []
      this.fetchAssets()
      this.$emit('update:is-show-modal-select-similar-asset', false)
    },
  },
}
</script>
<style lang="scss">
.selectable {
background:#DB536A ;
float: left;
width: 150px;
height: 150px;
margin: 5px;
position: relative;
}
.active_box {
width: 150px;
height: 150px;
padding: 5px;
}
.selectable img {
width:100%;
height:100%;
object-fit:cover;
}
.selectable i {
position: absolute;
left: 10px;
top: 10px;
font-size: 25px;
color: #DB536A;
background-color: #fff;
border-radius: 10%;
}
</style>
